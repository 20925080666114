import {ProductInterface} from "../../interfaces/ProductInterface";
import {Translate} from "../../utils/Translate";
import {selectProduct} from "../../../pages/productRangePage/ProductRangeSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {RouteEnum} from "../../enums/RouteEnum";
import {Link} from "react-router-dom";
import {useGetTranslationsQuery, useGetUserInfoQuery} from "../../../app/rootReducer";
import {currencyFormatter} from "../../utils/currencyFormatter";
import {StringTranslation} from "../../utils/StringTranslation";
import {ProductStateEnum} from "../../enums/ProductStateEnum";
import {MetricValue} from "../../interfaces/MetricValueInterface";
import {convertUnitToAbbreviation} from "../../utils/convertUnitToAbbreviation";
import {LanguageInterface} from "../../interfaces/LanguageInterface";

interface Props {
    product: ProductInterface;
    disableCheckbox?: boolean;
}

export const ProductGridItem = (props: Props) => {

    const dispatch = useAppDispatch()

    const userData = useGetUserInfoQuery()
    const {activeLanguage} = userData?.data || {activeLanguage: null}
    const {data: translations} = useGetTranslationsQuery()

    const {selectedProducts} = useAppSelector(state => state.productRange);
    const {product} = props
    const {productAttributes} = product
    let disableCheckbox = props.disableCheckbox

    if (props.disableCheckbox && !!selectedProducts.includes(product)) {
        disableCheckbox = false
    }

    return (
        <div className={"c-product-grid-item " + (selectedProducts.includes(product) ? 'is-active' : '')}>
            <label className="c-product-grid-item__top">
                <input type="checkbox" className={"checkbox " + (disableCheckbox ? "checkbox-disabled" : "")}
                       checked={!!selectedProducts.includes(product)}
                       onChange={() => dispatch(selectProduct(product))} disabled={disableCheckbox}/>
                {product.image &&
                    <div className="thumbnail">
                        <img src={product.image} alt="thumbnail"/>
                    </div>
                }
                <div className="favourites">
                    {/*<img src="/images/favourites.svg" alt="favourites"/>*/}
                </div>
            </label>

            <div className="c-product-grid-item__bottom">
                <div className="c-product-grid-item__bottom__title">
                    {!!activeLanguage &&
                        <h2>{Translate(product?.label, activeLanguage)}</h2>
                    }
                    {typeof product.color.label === 'object' && Object.keys(product.color.label).length > 0 &&
                        <div className="c-product-grid-item__bottom__title__color">
                            {!!product.color.hex &&
                                <div className="color" style={{backgroundColor: product.color.hex}}></div>
                            }
                            {!!activeLanguage &&
                                <span>{Translate(product.color.label, activeLanguage)}</span>
                            }
                        </div>
                    }
                    {product.productState === ProductStateEnum.archive &&
                        <div>
                            <div className="c-product-grid-item__bottom__title__old-model">
                                <span>{StringTranslation('old-models', translations, activeLanguage)}</span>
                            </div>
                        </div>
                    }
                </div>
                <div className="c-product-grid-item__bottom__content">
                    <div className="c-product-grid-item__bottom__content__specs">
                        {productAttributes?.map((item, index) => (
                            RenderAttributes(item, product.identifier, activeLanguage, translations, index)
                        ))}
                    </div>
                    <div className="c-product-grid-item__bottom__content__link">
                        <div className="c-btn c-btn--primary">
                            <Link
                                to={RouteEnum.PRODUCT_RANGE + `/${product.id}`}><span>{StringTranslation('view-product', translations, activeLanguage)}</span></Link>
                        </div>
                        {(product.prices && product.prices.length > 0) &&
                            <span
                                className="c-product-grid-item__bottom__content__link__price">{currencyFormatter(product.prices, activeLanguage)}</span>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


function RenderAttributes(item: any, identifier: string, activeLanguage: LanguageInterface | null, translations: any, index: number) {
    switch (true) {
        case (item.type === 'identifier'):
            return (
                <div key={index} className="c-product-grid-item__bottom__content__specs__spec">
                    <div className="label">{Translate(item.label, activeLanguage)}</div>
                    <div className="value">
                        {identifier}
                    </div>
                </div>
            )
        case (!!item.value && Object.values(item.value).length > 0 && Object.values(item.value)[0] !== null && item.type !== 'boolean' && item.type !== 'text' && item.type !== 'metric'):
            let attributeValueCount = 0
            return (
                <div key={index} className="c-product-grid-item__bottom__content__specs__spec">
                    <div className="label">{Translate(item.label, activeLanguage)}</div>
                    <div className="value">
                        {Object.values(item.value)?.map((value) => {
                            let translation = ''
                            if (attributeValueCount > 0) {
                                translation = ', '
                            }

                            translation += Translate(value, activeLanguage)
                            attributeValueCount++

                            return translation
                        })}
                    </div>
                </div>
            )
        case (!!item.value && item.type === 'boolean' && Object.values(item.value)[0] !== null):
            return (
                <div key={index} className="c-product-grid-item__bottom__content__specs__spec">
                    <div className="label">{Translate(item.label, activeLanguage)}</div>
                    <div className="value">
                        {StringTranslation(Object.values(item.value)[0] ? 'yes' : 'no', translations, activeLanguage)}
                    </div>
                </div>
            )
        case (!!item.value && item.type === 'text'):
            return (
                <div key={index} className="c-product-grid-item__bottom__content__specs__spec">
                    <div className="label">{Translate(item.label, activeLanguage)}</div>
                    <div className="value">
                        {Translate(item.value, activeLanguage)}
                    </div>
                </div>
            )
        case (!!item.value && item.type === 'metric' && Object.values(item.value)[0] !== null):
            let {amount, unit} = Object.values(item.value)[0] as MetricValue
            amount = amount.replace(/\.0000$/, '')
            unit = convertUnitToAbbreviation(unit)
            return (
                <div key={index} className="c-product-grid-item__bottom__content__specs__spec">
                    <div className="label">{Translate(item.label, activeLanguage)}</div>
                    <div className="value">
                        {amount} {unit}
                    </div>
                </div>
            )
        default:
            return null;
    }
}
