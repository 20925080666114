import {StringTranslation} from "../../utils/StringTranslation";
import {useGetTranslationsQuery, useGetUserInfoQuery} from "../../../app/rootReducer";

export const ProfileTab = () => {

    const userData = useGetUserInfoQuery()

    const {
        activeLanguage,
        salutation,
        firstName,
        insertion,
        lastName,
        function: functionName,
        email,
        phoneNumber,
    } = userData?.data || {activeLanguage: null}

    const {data: translations, isLoading: isTranslationsLoading} = useGetTranslationsQuery();
    const translationsLoaded = !isTranslationsLoading && !!activeLanguage;

    return (

        <div
            className="c-account-content c-account-content--profile">
            <div className="c-account-content__header">
                {translationsLoaded ?
                    <h1>{StringTranslation('personal-information', translations, activeLanguage)}</h1>
                    :
                    // eslint-disable-next-line jsx-a11y/heading-has-content
                    <h1 className="skeleton skeleton-title"></h1>
                }
            </div>

            <div className="c-account-content__wrapper">

                <div className="c-account-content__column">
                    {translationsLoaded ?
                        <>{!!salutation &&
                            <span
                                className="c-account-content__column__text c-account-content__column__text--label">
                                        {StringTranslation('salutation', translations, activeLanguage)}</span>
                        }</>
                        :
                        <span
                            className="c-account-content__column__text c-account-content__column__text--label skeleton skeleton-name"></span>
                    }

                    {translationsLoaded ?
                        <>
                            {!!salutation &&
                                <span className="c-account-content__column__text">{salutation}</span>
                            }
                        </>
                        :
                        <span className="c-account-content__column__text skeleton skeleton-name"></span>
                    }
                </div>

                <div className="c-account-content__column">
                    {translationsLoaded ?
                        <>{!!functionName &&
                            <span
                                className="c-account-content__column__text c-account-content__column__text--label">
                                        {StringTranslation('function', translations, activeLanguage)}</span>
                        }</>
                        :
                        <span
                            className="c-account-content__column__text c-account-content__column__text--label skeleton skeleton-name"></span>
                    }
                    {translationsLoaded ?
                        <>
                            {!!functionName &&
                                <span className="c-account-content__column__text">{functionName}</span>
                            }
                        </>
                        :
                        <span className="c-account-content__column__text skeleton skeleton-name"></span>
                    }
                </div>

                <div className="c-account-content__column">
                    {translationsLoaded ?
                        <>{!!firstName &&
                            <span
                                className="c-account-content__column__text c-account-content__column__text--label">
                                    {StringTranslation('first-name', translations, activeLanguage)}</span>
                        }</>
                        :
                        <span
                            className="c-account-content__column__text c-account-content__column__text--label skeleton skeleton-name"></span>
                    }

                    {translationsLoaded ?
                        <>
                            {!!firstName &&
                                <span className="c-account-content__column__text">{firstName}</span>
                            }
                        </>
                        :
                        <span className="c-account-content__column__text skeleton skeleton-name"></span>
                    }
                </div>


                <div className="c-account-content__column">
                    {translationsLoaded ?
                        <>{!!insertion &&
                            <span
                                className="c-account-content__column__text c-account-content__column__text--label">
                                        {StringTranslation('infix', translations, activeLanguage)}</span>
                        }</>
                        :
                        <span
                            className="c-account-content__column__text c-account-content__column__text--label skeleton skeleton-name"></span>
                    }

                    {translationsLoaded ?
                        <>
                            {!!insertion &&
                                <span className="c-account-content__column__text">{insertion}</span>
                            }
                        </>
                        :
                        <span className="c-account-content__column__text skeleton skeleton-name"></span>
                    }
                </div>

                <div className="c-account-content__column">
                    {translationsLoaded ?
                        <>{!!lastName &&
                            <span
                                className="c-account-content__column__text c-account-content__column__text--label">
                                        {StringTranslation('last-name', translations, activeLanguage)}</span>
                        }</>
                        :
                        <span
                            className="c-account-content__column__text c-account-content__column__text--label skeleton skeleton-name"></span>
                    }

                    {translationsLoaded ?
                        <>
                            {!!lastName &&
                                <span className="c-account-content__column__text">{lastName}</span>
                            }
                        </>
                        :
                        <span className="c-account-content__column__text skeleton skeleton-name"></span>
                    }
                </div>

                <div className="c-account-content__column">
                    {translationsLoaded ?
                        <>{!!email &&
                            <span
                                className="c-account-content__column__text c-account-content__column__text--label">
                                        {StringTranslation('emailaddresses', translations, activeLanguage)}</span>
                        }</>
                        :
                        <span
                            className="c-account-content__column__text c-account-content__column__text--label skeleton skeleton-name"></span>
                    }

                    {translationsLoaded ?
                        <>
                            {!!email &&
                                <span className="c-account-content__column__text">{email}</span>
                            }
                        </>
                        :
                        <span className="c-account-content__column__text skeleton skeleton-name"></span>
                    }
                </div>

                <div className="c-account-content__column">
                    {translationsLoaded ?
                        <>{!!phoneNumber &&
                            <span
                                className="c-account-content__column__text c-account-content__column__text--label">
                                        {StringTranslation('phone-number', translations, activeLanguage)}</span>
                        }</>
                        :
                        <span
                            className="c-account-content__column__text c-account-content__column__text--label skeleton skeleton-name"></span>
                    }
                    {translationsLoaded ?
                        <>
                            {!!phoneNumber &&
                                <span className="c-account-content__column__text">{phoneNumber}</span>
                            }
                        </>
                        :
                        <span className="c-account-content__column__text skeleton skeleton-name"></span>
                    }
                </div>

            </div>
        </div>

    )

}
