import {StringTranslation} from "../../utils/StringTranslation";
import QRCode from "react-qr-code";
import {useGetTranslationsQuery, useGetUserInfoQuery} from "../../../app/rootReducer";
import {
    useGetAuthSettingsQuery, useLazyGetAuthSettingsQuery,
    useSendAuthCodeMutation,
    useSetAuthMethodMutation
} from "../../../pages/accountPage/AccountSlice";
import {useState} from "react";
import {useHistory} from "react-router-dom";
import {RouteEnum} from "../../enums/RouteEnum";

export const SettingsTab = () => {

    let history = useHistory()

    const userData = useGetUserInfoQuery()
    const {data: translations, isLoading: isTranslationsLoading} = useGetTranslationsQuery();
    const {data: authSettings, isLoading: isLoadingAuthSettings} = useGetAuthSettingsQuery();

    const [authSettingsTrigger] = useLazyGetAuthSettingsQuery()
    const [sendAuthCodeTrigger] = useSendAuthCodeMutation()
    const [setAuthMethodTrigger] = useSetAuthMethodMutation()

    const [loadingAuthSettings, setLoadingAuthSettings] = useState<boolean>(false)
    const [selectedNewMethod, setSelectedNewMethod] = useState<string | null>(null)
    const [authCodeInput, setAuthCodeInput] = useState<string>('')
    const [isEmailSent, setIsEmailSent] = useState<boolean>(false)

    const {activeLanguage} = userData?.data || {activeLanguage: null}
    const {authentication_methods, current_authentication_method} = authSettings || {
        authentication_methods: [],
        current_authentication_method: ''
    }

    const translationsLoaded = !isTranslationsLoading && !!activeLanguage;
    const selectedMethod = authentication_methods.find(method => method.authentication_method === selectedNewMethod)

    return (
        <>
            <div className="c-account-content c-account-content--settings">

                <div className="c-account-content__header">
                    {translationsLoaded ?
                        <h1>{StringTranslation('settings', translations, activeLanguage)}</h1>
                        :
                        // eslint-disable-next-line jsx-a11y/heading-has-content
                        <h1 className="skeleton skeleton-title"></h1>
                    }
                </div>


                <div className="c-account-content__settings-content">

                    <div className="c-account-content__settings-container">
                        <div className="c-account-content__column">
                            {translationsLoaded ?
                                <>
                                    {!!current_authentication_method &&
                                        <span
                                            className="c-account-content__column__text c-account-content__column__text--label">
                                        {StringTranslation('active-2fa-method', translations, activeLanguage)}</span>
                                    }
                                </>
                                :
                                <span
                                    className="c-account-content__column__text c-account-content__column__text--label skeleton skeleton-name"></span>
                            }

                            {translationsLoaded && !isLoadingAuthSettings && !loadingAuthSettings ?
                                <>
                                    {!!current_authentication_method &&
                                        <span
                                            className="c-account-content__column__text">{current_authentication_method}</span>
                                    }
                                </>
                                :
                                <span className="c-account-content__column__text skeleton skeleton-name"></span>
                            }
                        </div>
                    </div>

                    {!!authentication_methods.length && !loadingAuthSettings &&
                        <div className="c-account-content__settings-container">
                            <h3>{StringTranslation('choose-another-2fa-method', translations, activeLanguage)}:</h3>

                            <div className="c-account-content__button-container">
                                {authentication_methods.map((method) => (
                                    <button className="c-btn c-btn--primary"
                                            onClick={() => setSelectedNewMethod(method.authentication_method)}
                                            disabled={method.authentication_method === current_authentication_method}>
                                        <span>{method.label}</span>
                                    </button>
                                ))}
                            </div>
                        </div>
                    }

                </div>

            </div>


            {!!selectedMethod && !loadingAuthSettings &&
                <div className="c-account-content c-account-content--settings-2fa">
                    <div className="c-account-content__header">
                        {translationsLoaded ?
                            <h1>{StringTranslation('set-the-method', translations, activeLanguage).replace('%method%', selectedMethod.label)}</h1>
                            :
                            // eslint-disable-next-line jsx-a11y/heading-has-content
                            <h1 className="skeleton skeleton-title"></h1>
                        }
                    </div>

                    {selectedMethod.authentication_method === 'authenticator' &&
                        <>
                            <div className="c-account-content__settings-container">
                                {translationsLoaded ?
                                    <>
                                        <h2>{StringTranslation('step-1-download-app', translations, activeLanguage)}</h2>
                                        <p>{StringTranslation('download-an-authenticator-app', translations, activeLanguage)}</p>
                                    </>
                                    :
                                    <>
                                        {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                                        <h2 className="skeleton skeleton-title"></h2>
                                        <p className="skeleton skeleton-text"></p>
                                    </>
                                }

                            </div>

                            <div
                                className="c-account-content__settings-container c-account-content__settings-container--row">
                                <div className="c-account-content__settings-container__text">
                                    {translationsLoaded ?
                                        <>
                                            <h2>{StringTranslation('step-2-scan-the-qr-code', translations, activeLanguage)}</h2>
                                            <p>{StringTranslation('scan-the-qr-code-with-the-authenticator-app', translations, activeLanguage)}</p>
                                        </>
                                        :
                                        <>
                                            {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                                            <h2 className="skeleton skeleton-title"></h2>
                                            <p className="skeleton skeleton-text"></p>
                                        </>
                                    }
                                </div>

                                <QRCode value={selectedMethod?.url ?? ''}/>
                            </div>

                            <div className="c-account-content__settings-container">
                                {translationsLoaded ?
                                    <>
                                        <h2>{StringTranslation('step-3-enter-the-code', translations, activeLanguage)}</h2>
                                        <p>{StringTranslation('enter-the-verification-code-you-receive-from-the-authenticator-app', translations, activeLanguage)}</p>
                                    </>
                                    :
                                    <>
                                        {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                                        <h2 className="skeleton skeleton-title"></h2>
                                        <p className="skeleton skeleton-text"></p>
                                    </>
                                }

                                {translationsLoaded ?
                                    <label>{StringTranslation('authentication-code', translations, activeLanguage)}:</label>
                                    :
                                    <label className="skeleton skeleton-title"></label>
                                }
                                <div className="c-account-content__settings-container__form">
                                    <input type="text" value={authCodeInput}
                                           onChange={(e) => setAuthCodeInput(e.target.value)}/>
                                    <button className="c-btn c-btn--primary" onClick={() => {
                                        setLoadingAuthSettings(true)
                                        setAuthMethodTrigger({
                                            authentication_method: selectedMethod.authentication_method,
                                            code: authCodeInput
                                        }).then(() => {
                                            authSettingsTrigger()
                                            history.push(RouteEnum.PRODUCT_RANGE)
                                        })
                                    }}>
                                        <span>{StringTranslation('activate', translations, activeLanguage)}</span>
                                    </button>
                                </div>
                            </div>
                        </>
                    }

                    {selectedMethod.authentication_method === 'email' &&
                        <>
                            <div className="c-account-content__settings-container">
                                {translationsLoaded ?
                                    <>
                                        <h2>{StringTranslation('step-1-receive-your-verification-code', translations, activeLanguage)}</h2>
                                        <p>{StringTranslation('receive-your-verification-code-by-email', translations, activeLanguage)}</p>
                                    </>
                                    :
                                    <>
                                        {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                                        <h2 className="skeleton skeleton-title"></h2>
                                        <p className="skeleton skeleton-text"></p>
                                    </>
                                }
                                <button className="c-btn c-btn--primary c-btn--fit-content"
                                        onClick={() => {
                                            setIsEmailSent(true)
                                            sendAuthCodeTrigger({authentication_method: selectedMethod.authentication_method})
                                        }}>
                                    {translationsLoaded ?
                                        <span>{StringTranslation('send-email', translations, activeLanguage)}</span>
                                        :
                                        <span className="skeleton skeleton-name"></span>
                                    }
                                </button>
                                {isEmailSent &&
                                    <p style={{marginTop: '1em'}}>{StringTranslation('activate', translations, activeLanguage)}</p>
                                }
                            </div>

                            <div className="c-account-content__settings-container">
                                {translationsLoaded ?
                                    <>
                                        <h2>{StringTranslation('step-2-enter-the-code', translations, activeLanguage)}</h2>
                                        <p>{StringTranslation('enter-the-verification-code-you-received-via-email', translations, activeLanguage)}</p>
                                    </>
                                    :
                                    <>
                                        {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                                        <h2 className="skeleton skeleton-title"></h2>
                                        <p className="skeleton skeleton-text"></p>
                                    </>
                                }


                                {translationsLoaded ?
                                    <label>{StringTranslation('authentication-code', translations, activeLanguage)}:</label>
                                    :
                                    <label className="skeleton skeleton-title"></label>
                                }
                                <div className="c-account-content__settings-container__form">
                                    <input type="text" value={authCodeInput}
                                           onChange={(e) => setAuthCodeInput(e.target.value)}/>
                                    <button className="c-btn c-btn--primary" onClick={() => {
                                        setLoadingAuthSettings(true)
                                        setAuthMethodTrigger({
                                            authentication_method: selectedMethod.authentication_method,
                                            code: authCodeInput
                                        }).then(() => {
                                            authSettingsTrigger()
                                            history.push(RouteEnum.PRODUCT_RANGE)
                                        })
                                    }}>
                                        {translationsLoaded ?
                                            <span>{StringTranslation('activate', translations, activeLanguage)}</span>
                                            :
                                            <span className="skeleton skeleton-name"></span>
                                        }
                                    </button>
                                </div>
                            </div>

                        </>
                    }

                </div>
            }

        </>
    )
}
